@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.event-list li, .event-list li > .scLooseFrameZone {
  display: block;
  position: relative;
  width: 100%;
  padding: 40px 0;
  background: #FFFFFF;
  color: #707070;
}

.event-list {
  background: transparent;
  font-size: 2em;
}

@media (min-width: 1200px) {
  .column-splitter > .col-xl-6:first-child .event-list {
    padding-right: 50px;
  }
}

@media (min-width: 1470px) {
  .column-splitter > .col-xl-6:first-child .event-list {
    padding-right: 100px;
  }
}

@media (min-width: 1630px) {
  .column-splitter > .col-xl-6:first-child .event-list {
    padding-right: 150px;
  }
}

@media (min-width: 1200px) {
  .column-splitter > .col-xl-6:last-child .event-list {
    padding-left: 50px;
  }
}

@media (min-width: 1470px) {
  .column-splitter > .col-xl-6:last-child .event-list {
    padding-left: 100px;
  }
}

@media (min-width: 1630px) {
  .column-splitter > .col-xl-6:last-child .event-list {
    padding-left: 150px;
  }
}

.event-list h3 {
  line-height: 1em;
}

.event-list li {
  display: flex;
  flex-wrap: wrap;
}

.event-list .event-list-time {
  margin-bottom: 1em;
  font-size: 1em;
  width: 100%;
}

.event-list .event-list-time .field-eventstart > span {
  margin-right: 1em;
  color: #ee2737;
  font-weight: bold;
}

.event-list .event-list-time .field-eventend {
  display: none;
}

.event-list .event-list-info {
  flex: 1;
}

.event-list .event-image img {
  width: 192px;
  margin-right: 1.4em;
}

.event-list .event-list-name {
  width: 100%;
  margin-bottom: 0.66667em;
  font-family: 'Frank Ruhl Libre', serif;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.event-list .event-list-name > a {
  color: #000;
  font-size: 1em;
}

.event-list .event-list-description {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 30px;
  overflow: hidden;
}

.event-list .event-list-description p {
  display: block;
  line-height: 30px;
}

.event-list .event-list-description br {
  display: none;
}

.event-list .event-list-place, .event-list .event-list-link {
  position: absolute;
  bottom: 5px;
  padding-left: 15px;
}

.event-list .event-list-place:before, .event-list .event-list-link:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.event-list .event-list-place {
  left: 10px;
}

.event-list .event-list-place:before {
  content: "";
}

.event-list .event-list-link {
  right: 10px;
}

.event-list .event-list-link a {
  color: #707070;
  font-size: 13px;
}

.event-list .event-list-link a:hover {
  border-bottom: 1px solid #89C6CC;
  color: #878787;
  text-decoration: none;
}

.event-list .event-list-link:before {
  content: "";
}

.event-list.pull-out-first ul {
  display: flex;
}

.event-list.pull-out-first ul li:first-child {
  display: none;
}
